import { createSlice } from "@reduxjs/toolkit";

const initState = {
  isToglled: false,
  isOpenDoor: false, // 도어 오픈
  isGameOpen: false, // 게임 오픈(모든 게임 완료시 false)
  isNext: true, // 다음문제여부 (문열때 사용)
  isChoice: false, // 정답선택여부
  isChoiceResult: false, // 선택결과(정답유무)
  isShowChoicePoint: false, // 문제당 획득 포인트 표시
  isModal: false, // 모달창 오픈
  totalGameCount: 0, // 전체 게임수
  gameCount: 0, // 진행 개수(인덱스값)(게임)
  optionCount: 0, // 진행 개수(인덱스값)(선택지)
  currentGameInfo: null, // 현재 게임 정보 (gameCount 기준, gameOptions 제외)

  memberId: "",
  myGameIdx: 0,
  heart: 50, // 생명
  hodu: 0, // 호두(캐쉬)
  pridx: 0, // 시대키
  prName: "", // 시대명
  kdidx: 0, // 왕국키
  rank: 0, // 미사용
  totalPoint: 0, // 누적 점수
  progress: 0, // 진행률
  gameType: "", // 게임 구분(KING, BONUS)
  gcIdx: 0, // 게임(왕)키
  optIdx: 0, // 선택지키
  gcPoint: 100, // 게임(왕)별 점수,100에서 20씩 차감
  gcAtStart: 0, // 게임시작시간(timestamp)
  gcAtEnd: 0, // 게임종료시간
  answer: "", // 제출정답
  isCorrect: null, // 정답유무
  status: "ON", // 현게임상태 (ON/OFF/?/?), 현재 미사용됨
};

const gameControlSlice = createSlice({
  name: "gameControlSlice",
  initialState: initState,
  reducers: {
    resetGameControl: (state, action) => {
      return {
        ...initState, // 기존 초기 상태값
        ...action.payload, // 특정 값만 덮어씌움
      };
    },
    setGameControl: (state, action) => {
      console.log("^^setGameControl action : ", action);

      const {
        isToglled,
        isOpenDoor,
        isGameOpen,
        isNext,
        isChoice,
        isChoiceResult,
        isShowChoicePoint,
        isModal,

        totalGameCount,
        gameCount,
        optionCount,

        prevOptionCount,

        currentGameInfo,

        memberId,
        myGameIdx,
        heart,
        hodu,
        pridx,
        prName,
        kdidx,
        rank,
        totalPoint,
        progress,
        gameType,
        gcIdx,
        optIdx,
        gcPoint,
        gcAtStart,
        gcAtEnd,
        answer,
        isCorrect,
        status,
      } = action.payload;

      // 필요한 값만 업데이트, 다른 상태값은 유지
      // if (typeof isToglled !== "undefined") {
      //   console.log("^^setGameControl isToglled : ", isToglled);
      //   state.isToglled = !state.isToglled;
      // }
      state.isToglled = !state.isToglled; // 단순 토글(대시보드-표정에 사용)

      if (typeof isOpenDoor !== "undefined") {
        console.log("^^setGameControl isOpenDoor : ", isOpenDoor);
        state.isOpenDoor = isOpenDoor;
      }
      if (typeof isGameOpen !== "undefined") {
        console.log("^^setGameControl isGameOpen : ", isGameOpen);
        state.isGameOpen = isGameOpen;
      }
      if (typeof isNext !== "undefined") {
        console.log("^^setGameControl isNext : ", isNext);
        state.isNext = isNext;
      }
      if (typeof isChoice !== "undefined") {
        console.log("^^setGameControl isChoice : ", isChoice);
        state.isChoice = isChoice;
      }
      if (typeof isChoiceResult !== "undefined") {
        console.log("^^setGameControl isChoiceResult : ", isChoiceResult);
        state.isChoiceResult = isChoiceResult;
      }
      if (typeof isModal !== "undefined") {
        console.log("^^setGameControl isModal : ", isModal);
        state.isModal = isModal;
      }
      if (typeof isShowChoicePoint !== "undefined") {
        console.log("^^setGameControl isShowChoicePoint : ", isShowChoicePoint);
        state.isShowChoicePoint = isShowChoicePoint;
      }
      if (typeof totalGameCount !== "undefined") {
        console.log("^^setGameControl totalGameCount : ", totalGameCount);
        state.totalGameCount = totalGameCount;
      }
      if (typeof gameCount !== "undefined") {
        console.log("^^setGameControl gameCount : ", gameCount);
        state.gameCount = gameCount;
      }
      if (typeof optionCount !== "undefined") {
        console.log("^^setGameControl optionCount : ", optionCount);
        state.optionCount = optionCount;
      }
      if (typeof prevOptionCount !== "undefined") {
        console.log("^^setGameControl prevOptionCount : ", prevOptionCount);
        state.prevOptionCount = prevOptionCount;
      }
      if (typeof currentGameInfo !== "undefined") {
        console.log("^^setGameControl currentGameInfo : ", currentGameInfo);
        state.currentGameInfo = currentGameInfo;
      }

      if (typeof memberId !== "undefined") {
        console.log("^^setGameControl memberId : ", memberId);
        state.memberId = memberId;
      }
      if (typeof myGameIdx !== "undefined") {
        console.log("^^setGameControl myGameIdx : ", myGameIdx);
        state.myGameIdx = myGameIdx;
      }

      if (typeof heart !== "undefined") {
        console.log("^^setGameControl heart : ", heart);
        state.heart = heart;
      }
      if (typeof hodu !== "undefined") {
        console.log("^^setGameControl hodu : ", hodu);
        state.hodu = hodu;
      }
      if (typeof pridx !== "undefined") {
        console.log("^^setGameControl pridx : ", pridx);
        state.pridx = pridx;
      }
      if (typeof prName !== "undefined") {
        console.log("^^setGameControl prName : ", prName);
        state.prName = prName;
      }
      if (typeof kdidx !== "undefined") {
        console.log("^^setGameControl kdidx : ", kdidx);
        state.kdidx = kdidx;
      }
      if (typeof rank !== "undefined") {
        console.log("^^setGameControl rank : ", rank);
        state.rank = rank;
      }
      if (typeof totalPoint !== "undefined") {
        console.log("^^setGameControl totalPoint : ", totalPoint);
        state.totalPoint = totalPoint;
      }
      if (typeof progress !== "undefined") {
        console.log("^^setGameControl progress : ", progress);
        state.progress = progress;
      }
      if (typeof gameType !== "undefined") {
        console.log("^^setGameControl gameType : ", gameType);
        state.gameType = gameType;
      }
      if (typeof gcIdx !== "undefined") {
        console.log("^^setGameControl gcIdx : ", gcIdx);
        state.gcIdx = gcIdx;
      }
      if (typeof optIdx !== "undefined") {
        console.log("^^setGameControl optIdx : ", optIdx);
        state.optIdx = optIdx;
      }
      if (typeof gcPoint !== "undefined") {
        console.log("^^setGameControl gcPoint : ", gcPoint);
        state.gcPoint = gcPoint;
      }
      if (typeof gcAtStart !== "undefined") {
        console.log("^^setGameControl gcAtStart : ", gcAtStart);
        state.gcAtStart = gcAtStart;
      }
      if (typeof gcAtEnd !== "undefined") {
        console.log("^^setGameControl gcAtEnd : ", gcAtEnd);
        state.gcAtEnd = gcAtEnd;
      }
      if (typeof answer !== "undefined") {
        console.log("^^setGameControl answer : ", answer);
        state.answer = answer;
      }
      if (typeof isCorrect !== "undefined") {
        console.log("^^setGameControl isCorrect : ", isCorrect);
        state.isCorrect = isCorrect;
      }
      if (typeof status !== "undefined") {
        console.log("^^setGameControl status : ", status);
        state.status = status;
      }
    },
  },
});

export const gameControlInitState = initState; // initialState를 export
export const { setGameControl, resetGameControl } = gameControlSlice.actions;
export default gameControlSlice.reducer;
